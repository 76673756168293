<template>
  <div>
    <Title :text="translations.nav_exhibitors_products" />
    <div class="viewcontent">
      <div>
        <div class="cont">
          <Title
            :text="translations.title_promoted_exhibitors"
            :background="'contrast_color'"
            :color="'primary_color'"
            :align="'middle'"
          />
          <div class="slidecont">
            <Slider
              class="slider"
              :images="allData.exhibPromoted"
              :type="'exhibitor'"
              :hover="true"
              v-if="allData.exhibPromoted"
            />
          </div>
          <Search
            :title="translations.label_exhibitor_search"
            :placeholder="translations.form_ph_exhibitor_search"
            class="search"
            v-model="search.exhibitor"
            @keyup.enter="searchExhibitor"
          />
        </div>
        <div class="cont">
          <Title
            :text="translations.title_promoted_products"
            :background="'contrast_color'"
            :color="'primary_color'"
            :align="'middle'"
          />
          <div class="slidecont">
            <Slider
              class="slider"
              :images="allData.prodPromoted"
              :type="'product'"
              :hover="true"
              v-if="allData.prodPromoted"
            />
          </div>
          <Search
            :title="translations.label_product_search"
            :placeholder="translations.form_ph_product_search"
            class="search"
            v-model="search.product"
            @keyup.enter="searchProduct"
          />
        </div>
      </div>
      <div class="banner" v-if="randomBanner">
        <Banner :type="1" :info="randomBanner" />
      </div>

      <ColumnCategories type="exhibitor" />
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import Slider from '@/components/Slider.vue'
  import ColumnCategories from '@/components/ColumnCategories.vue'
  import Banner from '@/components/Banner.vue'
  import Search from '../components/Search.vue'
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Exhibitors',

    components: {
      Title,
      Banner,
      Slider,
      ColumnCategories,
      Search,
    },
    data() {
      return {
        search: { exhibitor: '', product: '' },
      }
    },
    computed: {
      ...mapState(['allData', 'translations']),
      randomBanner() {
        let banner = null
        if (this.allData.banners) {
          let filterBanners = this.allData.banners.filter(
            (banner) => banner.location == 1
          )
          if (filterBanners.length > 0) {
            banner =
              filterBanners[Math.floor(Math.random() * filterBanners.length)]
          }
        }
        return banner
      },
    },
    methods: {
      ...mapActions(['getProducts', 'getExhibitors']),
      async searchProduct() {
        if (this.search.product.length > 0) {
          await this.getProducts({ keyword: this.search.product })
          router.push('/productlist')
        }
      },
      async searchExhibitor() {
        if (this.search.exhibitor.length > 0) {
          await this.getExhibitors({ keyword: this.search.exhibitor })
          router.push('/exhibitorlist')
        }
      },
    },
  }
</script>

<style scoped>
  .banner {
    height: 100px;
    margin: 0 20px 20px 20px;
  }
  .cont {
    width: 50%;
    display: inline-block;
  }
  .search {
    margin-bottom: 20px;
  }
  .slidecont {
    height: 125px;
    padding-bottom: 35px;
  }
  @media screen and (max-width: 1000px) {
    .cont {
      width: 100%;
      display: block;
    }
    .cont:last-of-type {
      margin-top: 20px;
    }
  }
</style>
