<template>
  <div>
    <div class="content border_category" v-if="allData.categoriesExh">
      <Title :text="defineTitle" type="subtitle" />
      <div class="categories">
        <Category
          class="category"
          v-for="(category, index) in allData.categoriesProd.slice(0, 4)"
          :key="index"
          :category="category"
          :limitList="15"
          :showButton="false"
          :type="'columns'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import Category from '@/components/Category.vue'
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'HomeFind',
    components: {
      Title,
      Category,
      ExhibitorTool,
    },
    props: {
      categoryTools: {
        type: Object,
        default: {
          title: 'Title Example',
          items: [
            { text: 'See list of all exhibitors', icon: 'far fa-eye' },
            {
              text: 'Download list of exhibitors',
              icon: 'fas fa-file-download',
            },
            { text: 'See list of all products', icon: 'far fa-eye' },
            {
              text: 'Download list of all products',
              icon: 'fas fa-file-download',
            },
          ],
        },
      },
      type: {
        type: String,
        default: 'exhibitor',
      },
    },
    computed: {
      ...mapState(['allData', 'translations']),
      defineTitle() {
        let result = ''
        switch (this.type) {
          case 'product':
            result = this.translations.title_product_categries
            break
          case 'exhibitor':
            result = this.translations.title_exhibitor_categries
            break
        }
        return result
      },
    },
  }
</script>

<style scoped>
  .content {
    border-width: 1px 0 1px 0;
    border-style: solid;
    display: inline-block;
    width: 100%;
    height: 100%;
    /* margin: 50px 0px 20px 0px; */
    vertical-align: top;
    padding: 30px 0px;
  }
  .categories {
    width: 100%;
    text-align: center;
  }
  .categories .category {
    display: inline-block;
    width: 24%;
    vertical-align: top;
    height: 550px;
  }
  .tools {
    text-align: center;
  }
  .tool {
    width: 20%;
    display: inline-block;
    vertical-align: top;
  }
  .category {
    margin: 2px;
  }

  @media screen and (max-width: 800px) {
    .categories .category {
      width: 45%;
      height: 520px;
    }
    .tool {
      width: 42%;
    }
  }
  @media screen and (max-width: 500px) {
    .categories .category {
      width: 70%;
    }
    .tool {
      width: 90%;
    }
  }
  @media screen and (max-width: 400px) {
    .categories .category {
      width: 90%;
    }
  }
</style>
